.date-picker-wrapper {
    display: inline-block;
    position: relative;
  }
  
  .date-picker {
    width: 196px;
    height: 40px;
    padding: 0 15px;
    border-radius: 7px;
    border: 1px solid #c4c4c4;
    font-size: 14px;
    line-height: 20px;
    color: #333;
    background-color: #f5f7fb;
  }
  
  .date-picker:focus {
    outline: none;
  }
  
  .date-picker-calendar {
    position: absolute;
    top: 42px;
    left: 0;
    z-index: 1;
    border-radius: 7px;
    box-shadow: 0px 1px 1px rgba(205, 205, 205, 0.25);
    background-color: #fff;
    font-size: 14px;
    line-height: 20px;
  }
  
  .date-picker-popper {
    z-index: 1;
  }