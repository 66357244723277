:root {
  --white: #fff;
  --primary: #4c248e;
  --secundary: #5a3d8a;
  --lilac: #cab0ff;
  --complementary: #365f82;
  --complementary2: #6c72fc;
}

/* html {
  font-size: 62.5%;
} */

body {
  margin: 0;
  background-color: #f5f7fb !important;
  box-sizing: border-box;
  background: #f5f7fb !important;
  height: auto;
  font-size: 1.6rem;
  max-width: 1920px;
  height: auto; 
}

body > iframe:nth-child(8) {
  pointer-events: none;
}

body > iframe:nth-child(9) {
  pointer-events: none;
}

body > iframe {
  pointer-events: none;
}

.footer-whats {
  margin-right: -10px;
}
.footer-whats a {
  margin-right: -10px;
}
.footer-whats img {
  margin-left: -45px;
  margin-top: 5px;
}

/* @font-face {
  font-family: 'SF-Semibold';
  src: url('/src/fonts/SF-Semibold.ttf') format('truetype');
} */

@font-face {
  font-family: "SF-Semibold";
  src: url("/src/fonts/SF-Pro-Rounded-Semibold.otf") format("opentype");
}

@font-face {
  font-family: "SF-Pro-Display";
  src: url("/src/fonts/SFPRODISPLAYBOLD.OTF") format("opentype");
}

@font-face {
  font-family: "SF-Pro-Regular";
  src: url("/src/fonts/SF-Pro-Rounded-Ultralight.otf") format("opentype");
}
/* @font-face {
  font-family: 'SF-Regular';
  src: local('SF-Regular'), url(/src/fonts/SF-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'SF-Semibold';
  src: local('SF-Semibold'), url(/src/fonts/SF-Semibold.ttf) format('truetype');
} */

h1 {
}

h2 {
}

h3 {
}

/* img {
  width: 100%;
  height: auto;
} */

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

footer {
  margin: 50px 0;
  text-align: center;
}

@media (max-width: 1060px) {
  html {
    font-size: 50%;
  }
}
/* @media (max-width: 950px){
  html {
      font-size: 40%;
  }
}
@media (max-width: 768px){
  html {
      font-size: 35%;
  }
}

@media (min-width: 270px){
  html {
      font-size: 62.5%;
  }
} */

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
